import actions from './actions';
import { ContextMenuClickPosition, TimelineType, TimelineView } from '../types/timeline';
import { CascaderOptions } from '../components/types';
import moment from 'moment-timezone';
import { Pilot } from '../../../../../common/types/pilot';
import { PreferredVersion } from '../../../../../common/types/preferredVersoon';
import { Tag } from '../../../../../common/types/tag';
import { CrewScheduleTimelineEntry } from '../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import { DutyTimelineEntry } from '../../../../../common/types/timeline/DutyTimelineEntry';
import {
  AircraftContractTimelineEntry,
  PilotContractTimelineEntry,
} from '../../../../../common/types/timeline/AircraftContractTimelineEntry';
import { PilotContract } from '../../../../../common/types/PilotContract';
import { ModalMode } from '../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../common/constants';
import { AircraftTimelineEntry } from '../../../../../common/types/timeline/AircraftTimelineEntry';
import { AircraftSchedule, MaintenanceAircraftSchedule } from '../../../../../common/types/aircraftSchedule';
import { CrewTimelineEntryType } from '../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../common/types/timeline/AircraftTimelineEntryType';
import { AircraftContract } from '../../../../../common/types/aircraftContract';
import { TimelinePeriod } from '../types/TimelinePeriod';
import { AircraftTimelineItem } from '../components/Timeline/AircraftTimeline/generator';
import { CrewTimelineItem } from '../components/Timeline/CrewTimeline/generator';
import { CrewSortOption, MaintenanceSortOption } from '../types/sortOptions';
import { Issue } from '../../../../../common/types/CombinedEntry';
import { ClientContract } from '../../../../../common/types/ClientContract';
import { ContractTimelineItem } from '../components/Timeline/ContractTimeline/generator';
import { ClientContractPeriodTimeline } from '../../../../../common/types/ClientContractPeriod';
import { MaintenanceTimelineEntry } from '../../../../../common/types/timeline/MaintenanceEntryType';
import { MaintenanceTimelineEntryType } from '../../../../../common/types/timeline/MaintenanceTimelineEntryType';
import { MaintenanceUser } from '../../../../../common/types/maintenance';
import { MaintenanceTimelineItem } from '../components/Timeline/MaintenanceTimeline/generator';
import { Sandbox } from '../../../../../common/types/sandbox';
import {
  MaintenanceAircraftTimelineEntry
} from '../../../../../common/types/timeline/MaintenanceAircraftTimelineEntry';
import { ScheduleTemplateTimelineEntry } from '../../../../../common/types/ScheduleTemplate';
import { isMobile, isTablet } from 'react-device-detect';

const isIPad = navigator.userAgent.includes("iPad") ||
  (navigator.userAgent.includes("Mac") && navigator.maxTouchPoints > 1);

export interface CrewSchedulingState {
  baseFilter: string[];
  isAircraftTimelineVisible: boolean;
  selectedTimelines: TimelineType[];
  visibleTimelines: TimelineType[];
  selectedTags: string[];
  crewSortOption: CrewSortOption | null;
  maintenanceSortOption: MaintenanceSortOption | null;
  crewCertifications: CascaderOptions[];
  maintenanceCertifications: CascaderOptions[];
  crewMemberBases: string[];
  maintenanceMemberBases: string[];
  selectedCrewMemberIds: string[];
  selectedMaintenanceMemberIds: string[];
  isPilotsLoaded: boolean;
  isMaintenanceLoaded: boolean;
  lastRequestCalledTimestamp: moment.Moment | null;
  pilots: Pilot[];
  maintenance: MaintenanceUser[];
  selectedPilots: Pilot[];
  selectedMaintenance: MaintenanceUser[];
  pilotsFNOrder: Pilot[];
  maintenanceFNOrder: MaintenanceUser[];
  preferredVersion: PreferredVersion;
  companyTags: Tag[];
  selectedClients: string[];
  selectedClientContracts: string[];
  selectedAircraftIdentifiers: string[];
  isShowTimelineLoaded: boolean;
  isTimelineLoading: boolean;
  timelinePeriod: TimelinePeriod;
  switchedTimelinePeriod: TimelinePeriod | null;
  visibleTimelinePeriod: TimelinePeriod;
  crewScheduleTimelineEntries: CrewScheduleTimelineEntry[];
  maintenanceTimelineEntries: MaintenanceTimelineEntry[];
  dutyScheduleTimelineEntries: DutyTimelineEntry[];
  pilotContractTimelineEntries: PilotContractTimelineEntry[];
  dutyTimeTimelineEntries: DutyTimelineEntry[];
  aircraftScheduleTimelineEntries: AircraftTimelineEntry[];
  mxAircraftScheduleTimelineEntries: MaintenanceAircraftTimelineEntry[];
  aircraftContractTimelineEntries: AircraftContractTimelineEntry[];
  jumpToDate: moment.Moment | null;
  currentView: TimelineView;
  allEntryTypesLoaded: boolean;
  pilotContractEntryForModal: Partial<PilotContract> | null;
  pilotContractModalMode: ModalMode;
  aircraftContractModalMode: ModalMode;
  groupModalMode: ModalMode;
  editAircraftScheduleEntry: AircraftSchedule | object;
  editMXAircraftScheduleEntry: MaintenanceAircraftSchedule | object;
  editAircraftContractEntry: AircraftContract | object;
  groupedAircraftContractModalMode: ModalMode;
  entryDisplayOptions: (
    CrewTimelineEntryType
    | AircraftTimelineEntryType
    | MaintenanceTimelineEntryType
  )[];
  crewEntryDisplayOptions: CrewTimelineEntryType[];
  maintenanceEntryDisplayOptions: MaintenanceTimelineEntryType[];
  isLoadingMoreEntries: boolean;
  isLoadingEntriesQuietly: boolean;
  cutItem: CrewTimelineItem | AircraftTimelineItem | null;
  copyItem: CrewTimelineItem | AircraftTimelineItem | null;
  cutItems: CrewTimelineItem[] | AircraftTimelineItem[];
  copyItems: CrewTimelineItem[] | AircraftTimelineItem[];
  deleteItems: CrewTimelineItem[] | AircraftTimelineItem[];
  contextMenuClickPosition: ContextMenuClickPosition | null;
  crewScheduleViolations: { [userID: string]: Issue[] };
  clientContracts: ClientContract[];
  editGroupTimelineEntry: ContractTimelineItem | null;
  editContractTimelineEntry: ContractTimelineItem | null;
  editMaintenanceTimelineEntry: MaintenanceTimelineItem | null;
  clientContractPeriodTimelineEntries: ClientContractPeriodTimeline[];
  remotePrinterModalMode: ModalMode;
  selectedItems: (CrewTimelineItem | MaintenanceTimelineItem | AircraftTimelineItem)[]
  selectedAircraftItems: AircraftTimelineItem[]
  selectedMXAircraftItems: AircraftTimelineItem[]
  selectedItemID: string
  isDrawerVisible: boolean
  isCorrectionsVisible: boolean
  isFilterDrawerPinned?: boolean
  isMeasuringTapeEnabled: boolean
  isCompactView: boolean;
  showTimezoneWarningBanner: boolean;
  noFilterDrawer: boolean;
  currentSharedSandbox: Sandbox
  scheduleTemplateTimelineEntries: ScheduleTemplateTimelineEntry[];
  isScheduleEntryChangelogModalVisible: boolean;
  scheduleEntryChangeLogLoading: boolean;
  timelineBufferPeriod: TimelinePeriod;
  loadedTimelineBufferPeriod: TimelinePeriod | null;
  loadedTimelinePeriod: { start: number, end: number };
  operationsOngoing: boolean;
}

const defaultTimelinePeriod = new TimelinePeriod(
  moment()
    // .subtract(2, 'day')
    .subtract(4, 'month').startOf('month').startOf('day'),
  moment()
    .add(4, 'month').endOf('month').endOf('day'),
);

const initialLoadedTimelinePeriod = {
  start: defaultTimelinePeriod.getStartMs(),
  end: defaultTimelinePeriod.getEndMs(),
}

const defaultVisibleTimeLine = new TimelinePeriod(
  moment()
    .startOf('month').startOf('day'),
  moment()
    .add(1, 'month').endOf('day')
)

const defaultTimelineBuffer = new TimelinePeriod(
  defaultTimelinePeriod.getStart().clone(),
  defaultTimelinePeriod.getEnd().clone(),
)

export const initialState: CrewSchedulingState = {
  baseFilter: [],
  isAircraftTimelineVisible: false,
  selectedTimelines: [],
  visibleTimelines: [TimelineType.Aircraft, TimelineType.Crew],
  selectedTags: [],
  crewSortOption: null,
  maintenanceSortOption: null,
  selectedCrewMemberIds: [],
  selectedMaintenanceMemberIds: [],
  isPilotsLoaded: false,
  isMaintenanceLoaded: false,
  isTimelineLoading: false,
  crewCertifications: [],
  maintenanceCertifications: [],
  crewMemberBases: [],
  maintenanceMemberBases: [],
  lastRequestCalledTimestamp: null,
  pilots: [],
  maintenance: [],
  selectedPilots: [],
  selectedMaintenance: [],
  pilotsFNOrder: [],
  maintenanceFNOrder: [],
  preferredVersion: 'current',
  companyTags: [],
  selectedClients: [],
  selectedClientContracts: [],
  selectedAircraftIdentifiers: [],
  isShowTimelineLoaded: false,
  timelinePeriod: defaultTimelinePeriod,
  visibleTimelinePeriod: defaultVisibleTimeLine,
  switchedTimelinePeriod: null,
  crewScheduleTimelineEntries: [],
  maintenanceTimelineEntries: [],
  dutyScheduleTimelineEntries: [],
  dutyTimeTimelineEntries: [],
  pilotContractTimelineEntries: [],
  aircraftScheduleTimelineEntries: [],
  mxAircraftScheduleTimelineEntries: [],
  aircraftContractTimelineEntries: [],
  jumpToDate: null,
  currentView: TimelineView.Month,
  allEntryTypesLoaded: false,
  pilotContractEntryForModal: null,
  pilotContractModalMode: {
    mode: MODAL_MODE.create,
    open: false,
  },
  groupModalMode: {
    mode: MODAL_MODE.create,
    open: false,
  },
  aircraftContractModalMode: {
    mode: MODAL_MODE.create,
    open: false,
  },
  groupedAircraftContractModalMode: {
    mode: MODAL_MODE.create,
    open: false,
  },
  editAircraftScheduleEntry: {},
  editMXAircraftScheduleEntry: {},
  editAircraftContractEntry: null,
  entryDisplayOptions: [
    CrewTimelineEntryType.DutySchedule,
    CrewTimelineEntryType.DutyTimes,
    CrewTimelineEntryType.PilotContract,
    AircraftTimelineEntryType.AircraftContract,
  ],
  crewEntryDisplayOptions: [],
  maintenanceEntryDisplayOptions: [],
  isLoadingMoreEntries: false,
  isLoadingEntriesQuietly: false,
  cutItem: null,
  copyItem: null,
  cutItems: [],
  copyItems: [],
  deleteItems: [],
  contextMenuClickPosition: null,
  crewScheduleViolations: {},
  // maintenanceScheduleViolations: {},
  clientContracts: [],
  editGroupTimelineEntry: null,
  editContractTimelineEntry: null,
  editMaintenanceTimelineEntry: null,
  clientContractPeriodTimelineEntries: [],
  remotePrinterModalMode: {
    mode: MODAL_MODE.create,
    open: false,
  },
  selectedItemID: null,
  isMeasuringTapeEnabled: false,
  isCompactView: isMobile || isTablet || isIPad,
  showTimezoneWarningBanner: false,
  isDrawerVisible: false,
  isCorrectionsVisible: false,
  noFilterDrawer: true,
  currentSharedSandbox: null,
  selectedItems: [],
  selectedAircraftItems: [],
  selectedMXAircraftItems: [],
  isFilterDrawerPinned: false,
  scheduleTemplateTimelineEntries: [],
  isScheduleEntryChangelogModalVisible: false,
  scheduleEntryChangeLogLoading: false,
  timelineBufferPeriod: defaultTimelineBuffer,
  loadedTimelineBufferPeriod: null,
  loadedTimelinePeriod: initialLoadedTimelinePeriod,
  operationsOngoing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_SELECTED_TAGS:
    case actions.SET_SELECTED_TIMELINES:
    case actions.SET_CREW_SORT_OPTION:
    case actions.SET_MAINTENANCE_SORT_OPTION:
    case actions.SET_COMPANY_SCHEDULE_VERSION:
    case actions.SET_PREFERRED_VERSION:
    case actions.SET_COMPANY_TAGS:
    case actions.SET_SELECTED_CLIENTS:
    case actions.SET_SELECTED_CLIENT_CONTRACTS:
    case actions.SET_SELECTED_CREW_MEMBER_IDS:
    case actions.SET_SELECTED_MAINTENANCE_MEMBER_IDS:
    case actions.SET_SELECTED_AIRCRAFT_IDENTIFIERS:
    case actions.SWITCH_TO_VIEW:
    case actions.SET_PILOT_CONTRACT_ENTRY_FOR_MODAL:
    case actions.SET_PILOT_CONTRACT_MODAL_MODE:
    case actions.SET_VISIBLE_TIMELINE_PERIOD:
    case actions.SET_EDIT_AIRCRAFT_SCHEDULE_ENTRY:
    case actions.SET_EDIT_MX_AIRCRAFT_SCHEDULE_ENTRY:
    case actions.SET_EDIT_AIRCRAFT_CONTRACT_ENTRY:
    case actions.SET_GROUP_MODAL_MODE:
    case actions.SET_AIRCRAFT_CONTRACT_MODAL_MODE:
    case actions.SET_GROUPED_AIRCRAFT_CONTRACT_MODAL_MODE:
    case actions.SET_TIMELINE_PERIOD:
    case actions.SET_VISIBLE_TIMELINES:
    case actions.SET_TIMELINE_LOADING:
    case actions.SET_CUT_ITEM:
    case actions.SET_COPY_ITEM:
    case actions.SET_BULK_CUT_ITEMS:
    case actions.SET_BULK_COPY_ITEMS:
    case actions.SET_CONTEXT_MENU_CLICK_POSITION:
    case actions.SET_REMOTE_PRINTER_MODAL_MODE:
    case actions.SET_IS_DRAWER_VISIBLE:
    case actions.SET_IS_CORRECTIONS_VISIBLE:
    case actions.SET_SELECTED_ITEM_ID:
    case actions.SET_IS_MEASURING_TAPE_ENABLED:
    case actions.SET_SWITCH_COMPACT_VIEW:
    case actions.SET_SELECTED_ITEMS:
    case actions.SET_NO_FILTER_DRAWER:
    case actions.SET_IS_FILTER_DRAWER_PINNED:
    case actions.SET_SELECTED_AIRCRAFT_ITEMS:
    case actions.SET_TIMELINE_BUFFER_PERIODS:
    case actions.SET_LOADED_TIMELINE_PERIOD:
      return { ...state, ...action.payload };
    case actions.SET_SWITCHED_TIMELINE_PERIOD:
      return {
        ...state,
        switchedTimelinePeriod: action.payload.switchedTimelinePeriod,
        // visibleTimelinePeriod: action.payload.switchedTimelinePeriod,
      };
    case actions.SET_BASE_FILTER:
      return { ...state, ...action.payload };
    case actions.SET_ENTRY_DISPLAY_OPTIONS:
      const all = action.payload.entryDisplayOptions;
      const crew = all.filter(option =>
        Object.values(CrewTimelineEntryType).includes(option as CrewTimelineEntryType),
      );
      const aircraft = all.filter(option =>
        Object.values(AircraftTimelineEntryType).includes(option as AircraftTimelineEntryType),
      );
      const maintenance = all.filter(option =>
        Object.values(MaintenanceTimelineEntryType).includes(
          option as MaintenanceTimelineEntryType,
        ),
      );
      return {
        ...state,
        entryDisplayOptions: action.payload.entryDisplayOptions,
        crewEntryDisplayOptions: crew,
        aircraftEntryDisplayOptions: aircraft,
        maintenanceEntryDisplayOptions: maintenance,
      };
    default:
      return state;
  }
}
