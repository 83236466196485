import moment from "moment";
import { call, delay, put, select } from "redux-saga/effects";
import { SetVisibleTimelinePeriodAction, scheduleActions, default as actions, SetLoadedTimelinePeriodAction } from '../../actions';
import {getIsLoadingEntriesQuietly, getIsTimelineLoading, getLoadedTimelineBufferPeriod, getLoadedTimelinePeriod} from "../../selectors";
import { selectVisibleTimelinePeriodSaga } from "../../selectors-saga";
import { loadAll } from "../timelineEntries/fetchAllEntries";

export function* watchSetVisibleTimelinePeriod(action: SetVisibleTimelinePeriodAction) {
  const loadedTimelinePeriod = yield select(getLoadedTimelinePeriod);
  const currVisibleTimelinePeriod = action?.payload?.visibleTimelinePeriod;
  const currMid = currVisibleTimelinePeriod?.getMidpoint();

  const twoMonthsInMs = 5.184E9;

  let isStillLoading = yield select(getIsTimelineLoading);
  yield put(scheduleActions.setTimelineLoading(true));
  if (currMid - loadedTimelinePeriod.start < twoMonthsInMs) {
    const newStart = loadedTimelinePeriod.start - twoMonthsInMs * 2
    const newEnd = loadedTimelinePeriod.end - twoMonthsInMs * 2
    const [from, to] = [
      moment(newStart).format(),
      moment(loadedTimelinePeriod.start).format(),
    ];
    yield put(scheduleActions.setLoadedTimelinePeriod({ start: newStart, end: newEnd }))
    yield call(loadAll, from, to, 'left', newEnd);
  }

  if (loadedTimelinePeriod.end - currMid < twoMonthsInMs) {
    const newStart = loadedTimelinePeriod.start + twoMonthsInMs * 2
    const newEnd = loadedTimelinePeriod.end + twoMonthsInMs * 2
    const [from, to] = [
      moment(loadedTimelinePeriod.end).format(),
      moment(newEnd).format(),
    ];
    yield put(scheduleActions.setLoadedTimelinePeriod({ start: newStart, end: newEnd }))
    yield call(loadAll, from, to, 'right', newStart);
  }
  yield put(scheduleActions.setTimelineLoading(false));

  // isStillLoading = yield select(getIsLoadingEntriesQuietly);
  // if (isStillLoading && (yield* isBufferPeriodLimitVisible()) ) {
  //   yield put(scheduleActions.setState({ isLoadingMoreEntries: true, isTimelineLoading: true }));
  //   while(true) {
  //     yield delay(10);
  //     isStillLoading = yield select(getIsLoadingEntriesQuietly);
  //     if (!isStillLoading) {
  //       yield put(scheduleActions.setState({ isLoadingMoreEntries: false, isTimelineLoading: false }));
  //       break;
  //     }
  //   }
  // }
}

function* isBufferPeriodLimitVisible() {
  const visibleTimelinePeriod = yield* selectVisibleTimelinePeriodSaga();
  const loadedTimelineBufferPeriod = yield select(getLoadedTimelineBufferPeriod);

  if (loadedTimelineBufferPeriod) {
    if (
      // timeline buffer start is visible
      (visibleTimelinePeriod.getStart().isBefore(loadedTimelineBufferPeriod.getStart()) &&
        visibleTimelinePeriod.getEnd().isAfter(loadedTimelineBufferPeriod.getStart())) ||
      // timeline buffer end is visible
      (visibleTimelinePeriod.getStart().isBefore(loadedTimelineBufferPeriod.getEnd()) &&
        visibleTimelinePeriod.getEnd().isAfter(loadedTimelineBufferPeriod.getEnd())) ||
      // visible timeline is bigger than the buffer—resize buffer and fetch with loading bar
      (visibleTimelinePeriod.getStart().isBefore(loadedTimelineBufferPeriod.getStart()) &&
        visibleTimelinePeriod.getEnd().isAfter(loadedTimelineBufferPeriod.getEnd()))
    ) {
      return true;
    }
  }
  return false;
}

export function* watchSetLoadedTimeline(action: SetLoadedTimelinePeriodAction) {
  //TODO: remove
  return
}

