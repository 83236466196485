import { ReFetchAircraftScheduleEntries, scheduleActions } from '../../actions';
import {
  selectAircraftScheduleTimelineEntriesSaga, selectTimelineBufferPeriod,
  selectVisibleTimelinePeriodSaga,
} from '../../selectors-saga';
import { select, call, put } from 'redux-saga/effects';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import api from '../../../../../../../services/api';
import { AircraftTimelineEntry } from '../../../../../../../common/types/timeline/AircraftTimelineEntry';
import _ from 'lodash';
import { NEW_ITEM_ID } from '../../../components/Timeline/constants';
import { getLoadedTimelinePeriod } from '../../selectors';
import { TimelinePeriod } from '../../../types/TimelinePeriod';
import moment from 'moment';

export function* reFetchAircraftScheduleEntries(action: ReFetchAircraftScheduleEntries) {
  const { aircraftIDs } = action.payload;
  // const timelineBufferPeriod = yield* selectTimelineBufferPeriod();

  const loadedTimelinePeriod = yield select(getLoadedTimelinePeriod)
  const timelineBufferPeriod = new TimelinePeriod(
    moment(loadedTimelinePeriod.start),
    moment(loadedTimelinePeriod.end)
  )

  const companyID = yield* selectCompanyIDSaga();

  const baseApiParams = {
    end_time__gt: timelineBufferPeriod.getStart().format(),
    start_time__lt: timelineBufferPeriod.getEnd().format(),
    limit: -1,
    user_ids: [],
    aircraft_ids: aircraftIDs
  };

  try {
    const {
      data: { Data },
    } = yield call(
      api.get,
      `/v1/companies/${companyID}/timeline/${AircraftTimelineEntryType.AircraftSchedule}`,
      {
        params: baseApiParams,
      },
    );

    const entriesArr = Data as AircraftTimelineEntry[];
    const existedEntries = yield* selectAircraftScheduleTimelineEntriesSaga();
    const filteredEntries = existedEntries.filter(e => {
      if (e.VirtualID === NEW_ITEM_ID) {
        return false;
      }
      if (!aircraftIDs?.length) {
        return false;
      }
      return !aircraftIDs?.includes(e.AssignedAircraftIDs?.[0]);
    });

    const newEntriesMap = _.keyBy(entriesArr, 'VirtualID');

    const mergedEntries = filteredEntries.map(entry =>
      newEntriesMap[entry.VirtualID] || entry
    );

    const combinedEntries = _.unionBy(mergedEntries, entriesArr, 'VirtualID');

    yield put(
      scheduleActions.setState({
        aircraftScheduleTimelineEntries: combinedEntries.filter(e => e.VirtualID !== NEW_ITEM_ID),
      })
    );
  } catch (err) {
    console.log(`Error fetching ${AircraftTimelineEntryType.AircraftSchedule} entries: `, err);
  }
}
