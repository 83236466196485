import { all, call, put } from 'redux-saga/effects';
import { scheduleActions } from '../../actions';
import { selectSelectedTimelinePeriodSaga } from '../../selectors-saga';
import { fetchTimelineEntries } from './fetchTimelineEntries';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { selectCompanySettingsSaga } from '../../../../../../../common/saga/selectors';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';

export function* fetchAllEntries() {
  yield put(scheduleActions.setState({ isShowTimelineLoaded: true, allEntryTypesLoaded: false, isLoadingEntriesQuietly: true }));

  const timePeriod = yield* selectSelectedTimelinePeriodSaga();

  yield put(scheduleActions.setTimelineBufferPeriod(timePeriod))

  yield call(loadAll, timePeriod.getStart().format(), timePeriod.getEnd().format());
  yield put(scheduleActions.setState({
    allEntryTypesLoaded: true,
    isShowTimelineLoaded: false,
    isLoadingEntriesQuietly: false,
    loadedTimelineBufferPeriod: timePeriod
  }));

}

export function* loadAll(from: string, to: string, append: string = 'none', referenceDate: number = 0) {
  const companySettings = yield* selectCompanySettingsSaga();

  const calls = [
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.PilotContract, append, referenceDate),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.DutyTimes, append, referenceDate),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.DutySchedule, append, referenceDate),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.CrewSchedule, append, referenceDate),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.AircraftSchedule, append, referenceDate),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.MaintenanceAircraftSchedule, append, referenceDate),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.AircraftContract, append, referenceDate),
    call(fetchTimelineEntries, from, to, MaintenanceTimelineEntryType.MaintenanceCrew, append, referenceDate),
  ];

  if (companySettings.ContractSchedule) {
    calls.push(
      call(
        fetchTimelineEntries,
        from,
        to,
        ContractTimelineEntryType.ClientContractPeriod,
        append,
        referenceDate,
      ),
    );
  }

  if (companySettings.ScheduleTemplates) {
    calls.push(
      call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.ScheduleTemplate, append, referenceDate),
    );
  }

  yield all(calls);
}
