import { getCommon, getCSMobile, getUserRole } from '../selectors';
import { put, select } from 'redux-saga/effects';
import { getCompany, getUser } from '../../../../../../redux/selectors';
import { FeatureFlag } from '../../../../../../utils/feature-flags/FeatureFlagsProvider';
import { AllowAction, CrewScheduleV2Permissions } from '../../../../../../common/types/permissions';
import { setPermissions } from '../actions';
import { DefinedPermissions } from '../../../../../../common/helpers/permissions';
import { isRegulationEditDisabled } from '../../../crew-scheduling-v3/legacy/utils/checks';

export function* initGuarder() {
  const { featureFlags } = yield select(getCommon);
  const { permissionList } = yield select(getCSMobile);
  const userRoles = yield select(getUserRole);
  const user = yield select(getUser);
  const { Settings } = yield select(getCompany);
  const companySettings = Settings;

  const isSubContractor = userRoles.includes('subContractor');
  const isAdmin = userRoles.includes('admin');
  const isPilot = userRoles.includes('pilot');
  const definedPermissions = user?.CompanyUsers[0]?.DefinedPermissions || [];

  const updatedList: CrewScheduleV2Permissions = { ...permissionList };

  // from company settings
  const allowPilotsToEditHistoricalDutyTimes = !!companySettings
    ?.AllowPilotsToEditHistoricalDutyTimes?.Enabled;
  const allowPilotDutyManagement = !!companySettings?.AllowPilotDutyManagement;
  const allowPilotDutyScheduleManagement = !!companySettings?.AllowPilotPlannedDutyManagement;
  const isPrioritizeIFRHours = !!companySettings?.PrioritizeIFRHours;
  updatedList.actions[
    AllowAction.allowPilotsToEditHistoricalDutyTimes
  ] = allowPilotsToEditHistoricalDutyTimes;
  updatedList.actions[AllowAction.allowPilotDutyManagement] = allowPilotDutyManagement;
  updatedList.actions[
    AllowAction.allowPilotDutyScheduleManagement
  ] = allowPilotDutyScheduleManagement;
  updatedList.actions[
    AllowAction.displayFDPOptionsInCrewSchedule
  ] = !!companySettings?.ShowFDPOptionsInCrewSchedule;
  updatedList.actions[
    AllowAction.displayReserveOptionsInCrewSchedule
  ] = !!companySettings?.ShowReserveOptionsInCrewSchedule;
  updatedList.actions[AllowAction.allowPrioritizeIFRHours] = isPrioritizeIFRHours;
  updatedList.actions[
    AllowAction.displayDateRangeCalculator
  ] = !!companySettings?.DateRangeCalculator;
  updatedList.actions[AllowAction.allowEditSubParts] = !isRegulationEditDisabled(
    true,
    companySettings,
    userRoles,
  );
  updatedList.actions[
    AllowAction.displaySinglePilot
  ] = (!companySettings?.IntegrateLoggingWithCrewScheduleV2 || companySettings?.AllowLoggingCustomerEditFlightInfo);
  updatedList.actions[
    AllowAction.displayAllDayVFR
  ] = (!companySettings?.IntegrateLoggingWithCrewScheduleV2 || companySettings?.AllowLoggingCustomerEditFlightInfo);
  updatedList.actions[
    AllowAction.allowDutyScheduleEditPilot
    ] = (!companySettings?.IntegrateLoggingWithCrewScheduleV2 || companySettings?.AllowLoggingCustomerEditFlightInfo);

  // enable TracksOnCall by default
  updatedList.actions[AllowAction.tracksOnCall] = !!companySettings?.TracksOnCall;

  if (isSubContractor) {
    updatedList.actions[AllowAction.addAircraftContract] = false;
    updatedList.actions[AllowAction.addPilotContract] = false;
    updatedList.actions[AllowAction.addDutyEntry] = false;
    updatedList.actions[AllowAction.addDutyTime] = false;
    updatedList.actions[AllowAction.addScheduleEntry] = false;
    updatedList.actions[AllowAction.addMaintenanceEntry] = false;
    updatedList.filters.companyClient = false;
    updatedList.filters.crewCertification = false;
    updatedList.filters.maintenanceCertification = false;
    updatedList.filters.crewMember = false;
    updatedList.filters.maintenanceMember = false;
    updatedList.filters.crewMemberBase = false;
    updatedList.filters.companyTag = false;
    updatedList.filters.displayOption = false;
    updatedList.filters.clientContract = false;
    updatedList.timelines.crewTimeline = false;
    updatedList.timelines.viewAircraftContracts = false;
  } else {
    updatedList.actions[AllowAction.addDutyEntry] = true;
    updatedList.actions[AllowAction.addDutyTime] = true;

    updatedList.actions[AllowAction.updateDutyEntry] = true;
    updatedList.actions[AllowAction.updateDutyTime] = true;

    updatedList.actions[AllowAction.deleteScheduleEntry] = true;
    updatedList.actions[AllowAction.deleteDutyEntry] = true;
    updatedList.actions[AllowAction.deleteDutyTime] = true;

    if (isPilot) {
      updatedList.actions[AllowAction.addDutyTime] = allowPilotDutyManagement;
      updatedList.actions[AllowAction.updateDutyTime] = allowPilotDutyManagement;

      updatedList.actions[AllowAction.addDutyEntry] = allowPilotDutyScheduleManagement;
      updatedList.actions[AllowAction.updateDutyEntry] = allowPilotDutyScheduleManagement;
    }

    if (allowPilotDutyManagement && (!companySettings?.IntegrateLoggingWithCrewScheduleV2 || companySettings?.AllowLoggingCustomerEditFlightInfo)) {
      updatedList.actions[AllowAction.allowEditFlightDetails] = true;
    }

    if (isAdmin) {
      // only allow admins to copy & paste
      updatedList.actions[AllowAction.copyAndPasteEntry] = true;
      updatedList.actions[AllowAction.allowEditFlightDetails] = true;
      updatedList.actions[AllowAction.createDutyTimeBeforeMostRecent] = true;
      updatedList.actions[AllowAction.allowPilotDutyScheduleManagement] = true;

      updatedList.actions[AllowAction.addDutyTime] = true;
      updatedList.actions[AllowAction.updateDutyTime] = true;
      updatedList.actions[AllowAction.addDutyEntry] = true;
      updatedList.actions[AllowAction.updateDutyEntry] = true;
      updatedList.actions[AllowAction.updateScheduleEntry] = true;
      updatedList.actions[AllowAction.updateMaintenanceEntry] = true;
      updatedList.actions[AllowAction.addMaintenanceEntry] = true;
      updatedList.actions[AllowAction.addScheduleEntry] = true;
    }

    if (
      definedPermissions.includes(DefinedPermissions.ALLOW_COMPLIANCE_OVERRIDE) &&
      featureFlags[FeatureFlag.ComplianceManagementOverride]
    ) {
      updatedList.actions[AllowAction.overrideEntryCompliance] = true;
    }
  }

  if (isPrioritizeIFRHours) {
    updatedList.actions[AllowAction.allowEditDutyScheduleIFRInput] = false;
    updatedList.actions[AllowAction.allowEditDutyScheduleVFRInput] = true;

    updatedList.actions[AllowAction.allowEditDutyTimeIFRInput] = false;
    updatedList.actions[AllowAction.allowEditDutyTimeVFRInput] = true;
  } else {
    updatedList.actions[AllowAction.allowEditDutyScheduleIFRInput] = true;
    updatedList.actions[AllowAction.allowEditDutyScheduleVFRInput] = false;

    updatedList.actions[AllowAction.allowEditDutyTimeIFRInput] = true;
    updatedList.actions[AllowAction.allowEditDutyTimeVFRInput] = false;
  }

  if (
    featureFlags[FeatureFlag.DetailedFlightTimes] ||
    (!companySettings?.IntegrateLoggingWithCrewScheduleV2 && !companySettings?.AllowLoggingCustomerEditFlightInfo)
  ) {
    updatedList.actions[AllowAction.allowEditDutyScheduleIFRInput] = false;
    updatedList.actions[AllowAction.allowEditDutyScheduleVFRInput] = false;
    updatedList.actions[AllowAction.allowEditDutyTimeIFRInput] = false;
    updatedList.actions[AllowAction.allowEditDutyTimeVFRInput] = false;
  }

  if (companySettings?.IntegrateLoggingWithCrewScheduleV2 && !companySettings?.AllowLoggingCustomerEditFlightInfo) {
    updatedList.actions[AllowAction.allowEditDutyTimeIFRInput] = false;
    updatedList.actions[AllowAction.allowEditDutyTimeVFRInput] = false;
  }

  // for version only
  if (companySettings?.Settings?.Versioning) {
    const preferredVersion = user?.CompanyUsers[0]?.PreferredVersion;
    const versionIsSandbox = preferredVersion.startsWith('sandbox');
    const versionIsCurrent = !preferredVersion.startsWith('latest');
    if (versionIsCurrent && !versionIsSandbox) {
      updatedList.actions.addScheduleEntry = false;
      updatedList.actions.addMaintenanceEntry = false;
      updatedList.actions.addDutyEntry = false;

      updatedList.actions.updateScheduleEntry = false;
      updatedList.actions.updateMaintenanceEntry = false;
      updatedList.actions.updateDutyEntry = false;

      updatedList.actions.deleteScheduleEntry = false;
      updatedList.actions.deleteDutyEntry = false;

      updatedList.actions.copyAndPasteEntry = false;
    }
  }

  yield put(setPermissions(updatedList));
}
